<template>
	<el-container :class="userinfo.menutype?'h100':''">
		<el-aside width="150px;" :style="{height:windowHeight+'px'}">
			<div class="uinfo">
				<el-popover ref="usemenus" popper-class="usermenus"
							placement="left-end"
							title="源古参生"
							width="210"
							trigger="click">
					<div class="list">
						<el-link icon="el-icon-user"> {{ userinfo.realname }}</el-link>
						<div style="text-align: right">--- {{ userinfo.role_name }}</div>
					</div>
					<el-link class="list" icon="el-icon-edit" @click="editpass"> {{ $t('index.edit_pass') }}</el-link>
					<el-link class="list" icon="el-icon-switch-button" @click="logout">
						{{$t('index.sign_out') }}
					</el-link>
				</el-popover>
				<el-avatar v-popover:usemenus :size="60" :src="userinfo.avatar?userinfo.avatar:logo"></el-avatar>
				<div class="uname">{{ userinfo.realname }}</div>
			</div>
			<el-menu :default-active="activeIndex"
				router
				background-color="#545c64"
				text-color="#fff"
				active-text-color="#ffd04b"
				:unique-opened="true">
				<div v-for="menu in menuslist" :key="menu.id">
					<el-submenu :index="''+menu.id" v-if="menu.children">
						<template slot="title">
							<i :class="menu.icon?menu.icon:'el-icon-arrow-right'"></i>
							<span slot="title">{{ menu.title }}</span>
						</template>
						<div v-for="submenu in menu.children" :key="submenu.id">
							<el-submenu :index="''+submenu.id" v-if="submenu.children">
								<i :class="submenu.icon?submenu.icon:'el-icon-arrow-right'"></i>
								<span slot="title">{{ submenu.title }}</span>
								<el-menu-item v-for="thmenu in submenu.children"
									:index="thmenu.router"
									:key="thmenu.id"
									@click="setitle(thmenu.title)">{{ thmenu.title }}
								</el-menu-item>
							</el-submenu>
							<el-menu-item v-else :index="submenu.router" @click="setitle(submenu.title)">
								{{ submenu.title }}
							</el-menu-item>
						</div>
					</el-submenu>
					<el-menu-item :index="menu.router" @click="setitle(menu.title)" v-else>
						<i :class="menu.icon?menu.icon:'el-icon-arrow-right'"></i>
						<span slot="title">{{ menu.title }}</span>
					</el-menu-item>
				</div>
			</el-menu>
		</el-aside>
		<el-container>
			<el-header v-if="isShowHeader" class="enter-print">
				<div class="el-page-header">
					<div class="el-page-header__left" v-show="isPreRouter" @click="goback">
						<i class="el-icon-back"></i>
					</div>
					<div class="el-page-header__content">{{ maintitle }}</div>
				</div>
				<div class="uinfo">
          <el-dropdown @command="setLanguage" class="setlang" style="margin-right: 15px">
                                    <span class="el-dropdown-link">
                                       {{ currLangText }}<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(lang,lidx) in langlist"
                                :key="lidx"
                                :command="lang.value">
                {{lang.label}}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
					<span class="navicons">
						<i :class="netStatus=='online'?'iconfont iconWiFi color-success':'iconfont iconoffline color-danger'"></i>
					</span>
					<el-badge class="navicons" :value="msgnum" :max="99" @click.stop="showNotice">
						<i class="el-icon-bell" @click="showNotice"></i>
					</el-badge>
					<el-dropdown @command="ShowRightDropdown">
            <span class="el-dropdown-link">
              <i class="el-icon-user"></i>{{ userinfo.realname }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item icon="el-icon-edit" command="editpass">{{
									$t('index.edit_pass')
								}}
							</el-dropdown-item>
							<el-dropdown-item icon="el-icon-switch-button" divided command="logout">
								{{ $t('index.sign_out') }}
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</el-header>
			<!--<transition name="el-fade-in-linear">-->
			<router-view @setitle="setitle" @setnoheader="setnoheader"/>
			<!--</transition>-->
		</el-container>
		<editpass :passVisible="passVisible" @changeVisible="changeVisible"></editpass>
	</el-container>
</template>
<script>
// @ is an alias to /src
import editpass from '@/views/system/editpass';
import packageconfig from "../../package.json";

export default {
	name: 'index',
	components: {editpass},
	data() {
		return {
			windowHeight: document.documentElement.clientHeight,
			logo: require('../assets/logo.png'),
			passVisible: false,
			printerVisible: false,
			maintitle: this.$t('index.dashboard'),
			isShowHeader: true,
			isPreRouter: true,
			systemsetting: {},
			msgnum: '',
			// 检查更新
			dialogVisible: false,
			closeOnClickModal: false,
			closeOnPressEscape: false,
			showClose: false,
			percentage: 0,
			strokeWidth: 200,
			updateTimeOut: null,
			updateTnterval: null,
			currLangText: 'EN',
      langlist: [
        {
          value: 'cn',
          label: '简体中文',
        },
        {
          value: 'en',
          label: 'Englist',
        }
      ]
		};
	},
	computed: {
		activeIndex: {
			get: function () {
				return this.$store.getters.getDefaultMenu;
			}
		},
		userinfo: {
			get: function () {
				return this.$store.getters.getUserInfo;
			}
		},
		menuslist: {
			get: function () {
				return this.$store.getters.getMenus || [];
			},
      set: function (val) {
        this.$store.commit('setMenus', val);
      }
		},
		netStatus: {
			get: function () {
				return this.$store.getters.getNetStatus || 'offline';
			},
			set(val) {
				this.$store.commit('setNetStatus', val);
			}
		},
		currLang: {
			get: function () {
				return this.$store.getters.getCurrLang || 'cn';
			},
			set(val) {
				this.$store.commit('setCurrLang', val);
			}
		},
		wsmsg: {
			get: function () {
				return this.$store.getters.getWsmsg || '';
			}
		}
	},
	methods: {
		setLanguage(e) {
		  if(!e){
        let e = this.$store.getters.getCurrLang || 'cn';
        this.currlang = e;
        let idx = this.langlist.findIndex((lang)=>{
          return lang.value==e;
        })
        if(idx!==-1){
          this.currLangText = this.langlist[idx].label;
        }
      }else{
        this.$confirm(this.$t('index.reloadpage'), this.$t('global.notice'), {
          confirmButtonText: this.$t('global.yes'),
          cancelButtonText: this.$t('global.no'),
          type: 'warning'
        }).then(() => {
          this.currlang = e;
          let idx = this.langlist.findIndex((lang)=>{
            return lang.value==e;
          })
          if(idx!==-1){
            this.currLangText = this.langlist[idx].label;
          }
          this.$store.commit('setCurrLang', e);
          this.$i18n.locale = e;
          setTimeout(() => {
            window.location.reload();
          }, 500);

        }).catch(() => {
          this.$message({
            type: 'info',
            message: this.$t('global.canceled')
          });
        });
      }
		},
		async getindex() {
			let res = await this.httpget('/system/publicapi/index?lang='+this.currLang);
			if (res.code === 0) {
        this.menuslist = res.data.menus;
        this.$store.dispatch('setMenus', res.data.menus);
				this.systemsetting = res.data.systemsetting;
				this.$store.dispatch('setSystemSetting', res.data.systemsetting);
				// 这个是判断是不是有设置好，没设置好就跳去设置页面，设置页面弄好后打开
				// if (Object.keys(this.systemsetting).length == 0 && this.activeIndex != '/system/setting') {
				// 	this.$alert(this.$t('index.setsys'), this.$t('global.notice'), {
				// 		confirmButtonText: this.$t('global.yes'),
				// 		callback: action => {
				// 			this.$router.push('/system/setting').catch(err => {
				// 				err
				// 			});
				// 		}
				// 	});
				// }
			}
		},
		async logout() {
			this.$confirm(this.$t('index.sureout')).then(async () => {
				let res = await this.httppost('/system/login/logout');
				if (res.code === 0) {
					this.$message({type: 'success', message: '成功退出登录'});
					this.$store.commit('setUserInfo', null);
					this.$store.commit('setToken', null);
					this.$store.dispatch('setMenus', null);
					this.$store.dispatch('setPowerMap', null);
          this.$router.push('/login').catch(err => {
            err
          });
				} else {
					this.$message({type: 'error', message: res.msg});
				}
			}).catch(() => {
				this.$message({type: 'info', message: this.$t('global.canceled')});
			});
		},
		editpass() {
			this.passVisible = true;
		},
		showNotice() { // 展示消息列表 ，暂时没有做的功能
			//
		},
		setGlobalPrinter() {
			this.printerVisible = true;
		},
		ShowRightDropdown(command) {
			if (command == 'editpass') {
				this.editpass();
			} else if (command == 'logout') {
				this.logout();
			}
		},
		// 修改密码返回
		changeVisible(val) {
			this.passVisible = val;
		},
		// 设置打印机返回
		changePrinterVisible(val) {
			this.printerVisible = val;
		},
		// 设置标题
		setitle(val) {
			this.maintitle = val;
		},
		// 设置没有标题
		setnoheader(val) {
			this.isShowHeader = val;
		},
		getCurrRouter(menus) { // 获取当前路由的标题
			var titletext = '';
			for (var idx in menus) {
				let menu = menus[idx];
				if (menu.router == this.activeIndex) {
					return menu.title;
				} else if (typeof (menu.children) != "undefined" && menu.children) {
					titletext = this.getCurrRouter(menu.children);
					if (titletext != '') {
						return titletext;
					}
				}
			}
			return titletext;
		},
		goback() {
			this.$router.go(-1);//返回上一层
		},
		updateOnlineStatus(e) {
			const {type} = e;
			this.netStatus = type === 'online' ? 'online' : 'offline';
			this.$store.commit('setNetStatus', type === 'online' ? 'online' : 'offline');
		},
	},
	mounted() {
		//设置标题栏
		document.title = '源古参生';
    //设置语言状态
    this.setLanguage();
    //获取菜单
		this.getindex();
		// 设置标题
		this.maintitle = this.getCurrRouter(this.menuslist);

		//设置网络状态
		let onlieststus = navigator.onLine ? 'online' : 'offline';
		this.netStatus = onlieststus;
		this.$store.commit('setNetStatus', onlieststus);
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
		// this.$ws.init();
		// this.$ws.send({opt:'check',token:this.$store.getters.getToken}, function(){
		//   window.console.log({opt:'check',token:this.$store.getters.getToken});
		//   window.console.log('send ok');
		// });

		//this.getLangList(1); // 把语言数据拉出来 缓存
	},
	created() {
		document.title = '源古参生 - ' + this.$t('global.version_v') + ' ' + packageconfig.version;
	},
	watch: {
		activeIndex() {
			// 判断是否有后退动作
			if (window.history.length <= 1) {
				this.isPreRouter = false;
			} else {
				this.isShowHeader = true;
			}
			// 设置标题
			this.maintitle = this.getCurrRouter(this.menuslist);
		},
		wsmsg(data, val) {
			window.console.log('wsmsg watch:', data, '---', val);
			if (data.msgtype == 'sync') {
				//
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('online', this.updateOnlineStatus);
		window.removeEventListener('offline', this.updateOnlineStatus);
		window.clearInterval(this.updateTnterval);
		window.clearInterval(this.updateTimeOut);
	}
}
</script>
