import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'
import home from '@/views/home.vue'
import login from '@/views/login.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    redirect: '/home',
    component: index,
    children: [
      {
        path: '/home',
        name: 'home',
        component: home
      },
      {
        path: '/system/user',
        name: 'user',
        component: () => import(/* webpackChunkName: "user" */ '@/views/system/user.vue')
      },
      {
        path: '/system/role',
        name: 'role',
        component: () => import(/* webpackChunkName: "role" */ '@/views/system/role.vue')
      },
      {
        path: '/masseur/index',
        name: 'masseur',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/masseur/index.vue')
      },
      {
        path: '/order/index',
        name: 'order',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/order/index.vue')
      },
      {
        path: '/fundlogs/index',
        name: 'fundlogs',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/fundlogs/index.vue')
      },
      {
        path: '/refund/index',
        name: 'refund',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/refund/index.vue')
      },
      {
        path: '/grade/index',
        name: 'grade',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/grade/index.vue')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
